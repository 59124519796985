// extracted by mini-css-extract-plugin
export var added = "index-module--added--3z6kp";
export var advertising = "index-module--advertising--3ooMj";
export var bing_item = "index-module--bing_item--+XdBh";
export var bing_second = "index-module--bing_second--DuHzV";
export var card_out = "index-module--card_out--CTskk";
export var card_shadow = "index-module--card_shadow--F3ZQH";
export var centered_logo = "index-module--centered_logo--nuqGh";
export var consult_now = "index-module--consult_now--Xtf8m";
export var data = "index-module--data--2ih-+";
export var earthContainer = "index-module--earthContainer--8xPTC";
export var flex1_item = "index-module--flex1_item--z6JQH";
export var flex_box = "index-module--flex_box--XG+cW";
export var flex_item = "index-module--flex_item--zYBzS";
export var head = "index-module--head--6UYD9";
export var left = "index-module--left--dT3GS";
export var ourservise = "index-module--ourservise--QZNJt";
export var ourservise_mobile = "index-module--ourservise_mobile--I1vRn";
export var overhere = "index-module--overhere--dQB+i";
export var right = "index-module--right--GnKaR";
export var second_title = "index-module--second_title--jqU6W";
export var text = "index-module--text--RDD5q";
export var title = "index-module--title--Qcnj4";